import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';
import { baseQueryWithReAuth } from '../reauth';
import { DEFAULT_LLM_KEY } from '../llm/types';
import type { ChatLogType, FileInChat } from '../chats-type';

export type VectorStoreFile = {
  fileId: string;
  fileName: string;
  fileContent: string;
  selected: boolean;
};

export type FEFileAssistant = {
  assistantId: string;
  threadId: string;
  vectorStoreId: string;
  vectorStoreFiles: Array<VectorStoreFile>;
};

export type FEChatType = {
  id: string;
  name: string;
  llm: string;
  files: Array<Omit<FileInChat, 'fileContent'>>;
  chatLog: ChatLogType;
  fileAssistant: null | FEFileAssistant;
};

export const createEmptyFEChat = (): Omit<FEChatType, 'id'> => ({
  name: '',
  llm: '',
  files: [],
  chatLog: [],
  fileAssistant: null
});

export type BEChatType = {
  id: string;
  chat_log: ChatLogType;
  name: string;
  llm: string;
  files: Array<{ file_id: string; file_name: string; selected: boolean }>;

  azure_file_assistant: null | {
    assistant_id: string;
    thread_id: string;
    vector_store_id: string;
    vector_store_files: Array<{
      file_id: string;
      filename: string;
      selected: boolean;
      file_content: string;
    }>;
  };
};

const chatToRemote = ({ id, chatLog, name, llm, files, fileAssistant, ...extra }: FEChatType): BEChatType => ({
  id,
  chat_log: chatLog,
  name: name,
  llm: llm || DEFAULT_LLM_KEY,
  files: (files || []).map(({ fileId: file_id, fileName: file_name, selected }) => ({
    file_id,
    file_name,
    selected: selected !== false
  })),
  azure_file_assistant: fileAssistant
    ? {
        assistant_id: fileAssistant.assistantId,
        thread_id: fileAssistant.threadId,
        vector_store_id: fileAssistant.vectorStoreId,
        vector_store_files: fileAssistant.vectorStoreFiles.map(
          ({ fileId: file_id, fileName: filename, fileContent: file_content, selected }) => ({
            file_id,
            file_content,
            filename,
            selected
          })
        )
      }
    : null,
  ...extra
});

export const chatsFromRemote = ({
  id,
  chat_log: chatLog,
  name,
  llm,
  files,
  azure_file_assistant,
  ...extra
}: BEChatType): FEChatType => ({
  id,
  chatLog,
  name,
  llm: llm || DEFAULT_LLM_KEY,
  files: (files || []).map(({ file_id: fileId, file_name: fileName, selected }) => ({
    fileId,
    fileName,
    selected: selected !== false
  })),
  fileAssistant: azure_file_assistant
    ? {
        assistantId: azure_file_assistant.assistant_id,
        threadId: azure_file_assistant.thread_id,
        vectorStoreId: azure_file_assistant.vector_store_id,
        vectorStoreFiles: azure_file_assistant.vector_store_files.map(
          ({ file_id: fileId, filename: fileName, file_content: fileContent, selected }) => ({
            fileId,
            fileName,
            fileContent,
            selected
          })
        )
      }
    : null,
  ...extra
});

export const chatsApi = createApi({
  reducerPath: 'chatsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['LIST-Chats', 'Chat'],
  endpoints: (builder) => ({
    getChats: builder.query({
      query: () => ({ url: '/chatlogs', method: 'get' }),
      transformResponse(baseQueryReturnValue: Array<BEChatType>, meta, arg) {
        return baseQueryReturnValue.map((item) => chatsFromRemote(item));
      },
      providesTags: ['LIST-Chats']
    }),
    getChat: builder.query({
      query: (id) => ({ url: `/chatlogs/${id}`, method: 'get' }),
      transformResponse(baseQueryReturnValue: BEChatType, meta, arg) {
        return chatsFromRemote(baseQueryReturnValue);
      },
      providesTags: (_, __, { id }) => [{ type: 'LIST-Chats', id }]
    }),
    addChat: builder.mutation({
      query: (chat) => {
        const { id, ...body } = chatToRemote(chat);
        return { url: '/chatlogs', method: 'post', body };
      },
      invalidatesTags: ['LIST-Chats']
    }),
    addChatSync: builder.mutation({
      query: (chat) => {
        const { id, ...body } = chatToRemote(chat);
        return { url: '/chatlogs', method: 'post', body };
      },
      async onQueryStarted(chats, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          console.error(e);
          dispatch(incrementSyncFailed());
        } finally {
          dispatch(decrementSyncLeft());
        }
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'LIST-Chats', id }]
    }),
    deleteChat: builder.mutation({
      query: (id: string) => {
        return { url: `/chatlogs/${id}`, method: 'delete', body: '' };
      },
      extraOptions: {
        skipBlocking: true
      },
      async onQueryStarted(removingId, { dispatch, queryFulfilled }) {
        dispatch(
          chatsApi.util.updateQueryData('getChats', undefined, (chats) => {
            const index = chats.findIndex(({ id }) => id === removingId);
            if (index !== -1) {
              chats.splice(index, 1);
            }
          })
        );
        await queryFulfilled;
      } // ,
      // invalidatesTags: ({ id }, __, ___) => ['LIST-Chats']
    }),
    renameChat: builder.mutation({
      query: ({ id, newName: name }) => ({ url: `/chatlogs/${id}`, method: 'PATCH', body: { name } }),
      invalidatesTags: () => ['LIST-Chats']
    }),
    replaceChat: builder.mutation({
      query: ({ id, ...chat }) => {
        return {
          url: `/chatlogs/${id}`,
          method: 'put',
          body: chatToRemote({ id, ...chat })
        };
      },
      invalidatesTags: () => {
        return [{ type: 'LIST-Chats' }];
      },
      transformResponse(baseQueryReturnValue: BEChatType) {
        return chatsFromRemote(baseQueryReturnValue);
      },
      async onQueryStarted(chat, { dispatch, queryFulfilled }) {
        dispatch(
          chatsApi.util.updateQueryData('getChats', undefined, (chats) => {
            const index = chats.findIndex(({ id }) => id === chat?.id);
            if (index !== -1) {
              chats.splice(index, 1, chat);
            }
          })
        );
        await queryFulfilled;
      }
    })
  })
});

export const {
  useGetChatsQuery,
  useAddChatMutation,
  useAddChatSyncMutation,
  useRenameChatMutation,
  useReplaceChatMutation,
  useDeleteChatMutation
} = chatsApi;

type SyncStateType = {
  inSync: boolean;
  total: number;
  left: number;
  failed: number;
  syncIsDone: boolean;
};

type RootHasSyncStateType = {
  chatsApiSync: SyncStateType;
};

const initialState = {
  inSync: false,
  total: 0,
  left: 0,
  failed: 0,
  syncIsDone: false
};

export const syncChatsSlice = createSlice({
  name: 'chatsApiSync',
  initialState,
  reducers: {
    startSync(state, { payload: { total } }) {
      state.total = total;
      state.left = total;
      state.inSync = true;
    },
    decrementSyncLeft(state) {
      state.left -= 1;
    },
    incrementSyncFailed(state) {
      state.failed += 1;
    },
    finishSync(state) {
      state.inSync = false;
      state.syncIsDone = true;
    }
  }
});

export const { startSync, decrementSyncLeft, incrementSyncFailed, finishSync } = syncChatsSlice.actions;

export const selectInSync = (state: RootHasSyncStateType) => state.chatsApiSync.inSync;
export const selectSyncTotal = (state: RootHasSyncStateType) => state.chatsApiSync.total;
export const selectSyncLeft = (state: RootHasSyncStateType) => state.chatsApiSync.left;
export const selectSyncFailed = (state: RootHasSyncStateType) => state.chatsApiSync.failed;
export const selectSyncIsDone = (state: RootHasSyncStateType) => state.chatsApiSync.syncIsDone;
export const selectReadyForNewChats = (state: RootHasSyncStateType) =>
  selectSyncIsDone(state) && selectSyncFailed(state) === 0;
