import React, { MutableRefObject, useCallback, useState } from 'react';
import { FaRegClipboard, FaClipboardCheck } from 'react-icons/fa';
import clsx from 'clsx';

type Params = {
  htmlElementRef: MutableRefObject<null | HTMLDivElement>;
};

function CopyToClipboardIcon({ htmlElementRef }: Params) {
  const [showIsCopied, setShowIsCopied] = useState(false);

  const copyText = useCallback(() => {
    setShowIsCopied(() => true);
    const type = 'text/html';
    const asHTML = htmlElementRef?.current?.innerHTML || '';
    const blob = new Blob([asHTML], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard
      .write(data)
      .then(() => {
        console.log('Text copied to clipboard');
        setTimeout(() => {
          setShowIsCopied(() => false);
        }, 1000);
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  }, [htmlElementRef]);

  return (
    <div style={{ cursor: 'pointer' }} className={clsx('clipboard', showIsCopied && 'clipboard-copied')}>
      <div className="clipboard-icons">
        <FaRegClipboard onClick={copyText} />
        <FaClipboardCheck />
      </div>
    </div>
  );
}

export default CopyToClipboardIcon;
