import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as orama from '@orama/orama';

import { selectSearchQuery, selectSearchResults, setSearchResults, setQuery, setCurrentSession } from '../data/ui';
import type { ChatEntryType, ChatType } from '../data/chats-type';

const BatchSize = 100;

const searchIndexDb = await orama.create({
  schema: {
    id: 'string',
    chatName: 'string',
    chatPrompt: 'string',
    botMessage: 'string'
  }
});

function makeId(chatIndex: string, messageIndex: string) {
  return `${chatIndex}:${messageIndex}`;
}

function getChatIndex(id: string) {
  return parseInt(id.split(':')[0], 10);
}

type PreparedDataType = { id: string; chatName: string } & ChatEntryType;

function prepareData(data: Array<ChatType>): Array<PreparedDataType> {
  return data
    .filter(({ name, chatLog }) => !!name || !!chatLog?.length)
    .reduce(
      (res: Array<PreparedDataType>, { id, name, chatLog }) => [
        ...res,
        ...chatLog.map((item) => ({
          chatPrompt: item.chatPrompt,
          botMessage: item.botMessage,
          chatName: name,
          id: id as string
        }))
      ],
      []
    );
}

export default function useSearch() {
  const dispatch = useDispatch();
  const results = useSelector(selectSearchResults);
  const query = useSelector(selectSearchQuery);

  const reindex = useCallback((data: Array<ChatType>) => {
    const preparedData = prepareData(data);
    const ids = preparedData.map(({ id }) => id);
    orama.removeMultiple(searchIndexDb, ids, BatchSize).then((removed) => {
      orama.insertMultiple(searchIndexDb, preparedData as Array<never>, BatchSize).catch(() => null);
    });
  }, []);

  const search = useCallback(
    (query: string) => {
      dispatch(setQuery(query));
      if (query) {
        orama
          .search(searchIndexDb, {
            term: query
          })
          .then(({ hits }) => {
            dispatch(setSearchResults(hits));
            const sessionToSet = hits?.length > 0 ? getChatIndex(hits[0].id) : 0;
            dispatch(setCurrentSession(sessionToSet));
          });
      } else {
        dispatch(setSearchResults(null));
        dispatch(setCurrentSession(0));
      }
    },
    [dispatch]
  );

  return {
    reindex,
    search,
    results,
    resultIds: results?.map(({ id }) => id) || [],
    isSearch: !!query,
    query
  };
}
