import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { selectInputPrompt } from '../data/ui';
import useSuggestions from '../hooks/useSuggestions';
import { selectedModalSuggestionSelector } from '../data/prompts/suggestions-slice';
import PromptInputFactory from './PromptInputFactory';
import MHPArticleSummariser from './MHPArticleSummariser';
import { useRequest } from '../hooks/useRequest';
import Next15Policies from './Next15Policies';
import ModalsBarBlock from './ModalsBarBlock';
import { HRS_BOT, HRS_PAYROLL, MHP_PROMPT, POLICIES_PROMPT } from '../data/llm/openai';
import type { ModalDetailsType } from './modalsbar-types';
import { SelectedChatContext } from '../context/SelectedChatContext';
import HRPayroll from './HRPayroll';
import HRBot from './HRBot';

const MODALS_DETAILS: Array<ModalDetailsType> = [
  {
    prompt: 'Summarise articles',
    suggested: MHP_PROMPT,
    description: 'Summarise an article',
    renderModal: (selectedPrompt, inputPrompt, onClose, onDone) => (
      <MHPArticleSummariser inputPrompt={inputPrompt} onClose={onClose} onDone={onDone} />
    ),
    isTool: true
  },
  {
    prompt: 'Policies',
    suggested: POLICIES_PROMPT,
    renderModal: (selectedPrompt, inputPrompt, onClose, onDone) => <Next15Policies onClose={onClose} onDone={onDone} />,
    isTool: true
  },
  {
    prompt: 'Payroll',
    suggested: HRS_PAYROLL,
    renderModal: (selectedPrompt, inputPrompt, onClose, onDone) => <HRPayroll onClose={onClose} onDone={onDone} />,
    isTool: true
  },
  // {
  //   prompt: 'HR Bot',
  //   suggested: HRS_BOT,
  //   renderModal: (selectedPrompt, inputPrompt, onClose, onDone) => <HRBot onClose={onClose} onDone={onDone} />,
  //   isTool: true
  // },
  {
    prompt: 'Summarise',
    suggested: '/summarise',
    description: 'Summarise information to desired length and form. ',
    // howItWorks: "Summarise this text: {{text}}. Into this form and/or length - {{user_value}}",
    howItWorks:
      'Could you please provide a concise and comprehensive summary of this text : "{{text}}" .\n --- \n The summary should capture the main points and key details of the text while conveying the author\'s intended meaning accurately.Please ensure that the summary is well-organised and easy to read, with clear headings and subheadings to guide the reader through each section. The length of the summary should be in this form and/or length - {{user_value}}',
    isTool: false,
    UserInput2: 'Instructions to summarise',
    placeholderText1: 'Paste or type the text you want to summarise...',
    placeholderText2:
      'Please provide specific instructions for summarisation such as length, number of sentences, form and so on.'
  },
  {
    prompt: 'Adjust Tone',
    suggested: '/adjust tone',
    description: 'Adjust the tone of text.',
    howItWorks: 'Adjust the tone of this text:  " {{text}}". \n --- \n To be {{user_value}} in tone.',
    isTool: false,
    UserInput2: 'Select Tone',
    placeholderText1: 'Type or paste your text here....',
    placeholderText2: 'Choose the tone of the content (e.g., Formal, Casual, Informative)'
  },
  {
    prompt: 'Explain',
    suggested: '/explain',
    description: 'Explains a term concisely at a university graduate level.',
    howItWorks:
      'Please explain what this means at a university graduate level: "{{text}}".\n --- \n Use examples if appropriate. Take into account the context of my job when answering. I heard the term in the context of {{user_value}}. Please answer in around 100 words. Use bullet points for your answer.',
    isTool: false,
    UserInput2: 'Context',
    placeholderText1: 'Enter the text/content to be explained....',
    placeholderText2: 'Provide context or background information...'
  },
  {
    prompt: 'Ideate',
    suggested: '/ideate',
    description: 'Generate ideas on a specific topic.',
    howItWorks: 'Create some ideas for "{{user_value}}" and ways in which I could explore them further.',
    isTool: false,
    UserInput2: 'Your topic/subject',
    placeholderText1: '',
    placeholderText2: "Enter the topic you'd like to brainstorm about..."
  }
];

type ParamsType = {
  onModalToggle: (shown: boolean, finishedTask: boolean) => void;
};

const ModalsBar = ({ onModalToggle }: ParamsType) => {
  const inputPrompt = useSelector(selectInputPrompt);
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const { autocompleteSuggestions } = useRequest({ llmKey: chat?.llm });

  const [selectedPrompt, setSelectedPrompt] = useState<ModalDetailsType | null>(null);
  const selectedModalSuggestion = useSelector(selectedModalSuggestionSelector);
  const { turnOffSuggestions } = useSuggestions();

  const handlePromptSelection = (promptDetail: ModalDetailsType) => {
    setSelectedPrompt(() => promptDetail);
  };

  useEffect(() => {
    const selectedDetails = MODALS_DETAILS.find(({ suggested }) => suggested === selectedModalSuggestion);
    setSelectedPrompt(() => selectedDetails || null);
  }, [selectedModalSuggestion]);

  const onClose = useCallback(() => {
    setSelectedPrompt(() => null);
    turnOffSuggestions();
  }, [turnOffSuggestions]);

  const onDone = useCallback(
    (finishedTask: boolean) => {
      setSelectedPrompt(() => null);
      turnOffSuggestions();
      onModalToggle(false, finishedTask);
    },
    [onModalToggle, turnOffSuggestions]
  );

  useEffect(() => {
    if (onModalToggle) {
      onModalToggle(!!selectedPrompt, false);
    }
  }, [selectedPrompt, onModalToggle]);

  return (
    <div className="modalBar">
      <ModalsBarBlock
        title={'Prompts'}
        onPromptSelected={handlePromptSelection}
        promptDetailsList={MODALS_DETAILS.filter(({ isTool }) => !isTool).filter(({ suggested }) =>
          autocompleteSuggestions.includes(suggested)
        )}
      />
      <ModalsBarBlock
        title={'Tools'}
        onPromptSelected={handlePromptSelection}
        promptDetailsList={MODALS_DETAILS.filter(({ isTool }) => isTool).filter(({ suggested }) =>
          autocompleteSuggestions.includes(suggested)
        )}
      />
      {selectedPrompt && createPortal(PromptInputFactory(selectedPrompt, inputPrompt, onClose, onDone), document.body)}
    </div>
  );
};

export default ModalsBar;
