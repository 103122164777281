import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import { selectCurrentUser, setUser } from '../data/ui';
import { AuthProviders } from '../utils/auth';
import { SelectedChatProvider } from '../context/SelectedChatContext';

export const Loading = () => <h3>Loading...</h3>;

export function prepareOktaCredentials({
  sub,
  email = '', // in case okta doesn't provide email it will rise error with empty value
  name = 'User' // in case okta doesn't provide username we set default to "User"
}: {
  sub: string;
  email?: string;
  name?: string;
}) {
  const allowedDomains = process.env.REACT_APP_LOGIN_DOMAIN!.split(',');

  if (!allowedDomains.some((suffix) => email.endsWith(suffix))) {
    throw new Error('Unexpected email address');
  }
  return { uid: sub, email, displayName: name, provider: AuthProviders.OKTA };
}

export function AuthenticatedRoot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const { oktaAuth, authState } = useOktaAuth();
  const isAuthenticated = !!authState?.isAuthenticated;

  useEffect(() => {
    if (!currentUser && isAuthenticated) {
      oktaAuth.getUser().then((user) => {
        try {
          dispatch(setUser(prepareOktaCredentials(user)));
          navigate('/');
        } catch (err) {
          oktaAuth.signOut().then(() => {
            alert(`Unfortunately, you are not allowed to sign in to the ${process.env.REACT_APP_COMPANY} Bot.`);
          });
        }
      });
    }
  }, [currentUser, dispatch, isAuthenticated, navigate, oktaAuth]);

  if (!currentUser) {
    return <Navigate to="login" />;
  }

  return (
    <SelectedChatProvider>
      <Outlet />
    </SelectedChatProvider>
  );
}
